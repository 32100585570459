import React, { useState, useEffect } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import profile from "../assets/images/profile.jpg";
import { Button, Layout, Menu, theme } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SendIcon from "@mui/icons-material/Send";
import CampaignIcon from "@mui/icons-material/Campaign";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TempleBuddhistIcon from "@mui/icons-material/TempleBuddhist";
import { Footer } from "antd/es/layout/layout";
import SaveIcon from "@mui/icons-material/Save";
import SettingsIcon from "@mui/icons-material/Settings";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import PersonIcon from "@mui/icons-material/Person";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import TagIcon from "@mui/icons-material/Tag";
import KeyIcon from "@mui/icons-material/Key";
import FilterListIcon from "@mui/icons-material/FilterList";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import GroupsIcon from "@mui/icons-material/Groups";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import HistoryIcon from "@mui/icons-material/History";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CableIcon from "@mui/icons-material/Cable";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import EmailIcon from "@mui/icons-material/Email";

// import CloudCircleIcon from "@mui/icons-material/CloudCircle";
import "./MainLayout.css";
import axios from "axios";

const { Header, Sider, Content } = Layout;

const MainLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();

  // State to manage selected key of menu
  const [selectedKey, setSelectedKey] = useState(() => {
    return localStorage.getItem("selectedKey") || "";
  });

  // Function to handle menu item click
  const handleMenuClick = ({ key }) => {
    setSelectedKey(key);
    if (key === "signout") {
      // Handle signout
    } else {
      navigate(key);
    }
  };

  useEffect(() => {
    // Store the selected key in local storage when it changes
    localStorage.setItem("selectedKey", selectedKey);
  }, [selectedKey]);

  const handleLogout = async () => {
    try {
      const response = await axios.post("/api/user/logout");

      if (response.status === 200) {
        // Clear local storage
        localStorage.clear();

        // Show success message and navigate
        toast.success("Logout successful!");
        navigate("/");
      } else {
        // Handle unexpected response status
        toast.error("Unexpected response while logging out.");
      }
    } catch (error) {
      // Handle network errors or other issues
      console.error(error);
      toast.error("Something went wrong while logging out.");
    }
  };

  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    // Retrieve the user's name from local storage
    const storedFirstName = localStorage.getItem("firstname");
    const storedLastName = localStorage.getItem("lastname");
    const storedEmail = localStorage.getItem("email");
    if (storedFirstName) {
      setFirstName(storedFirstName);
    }
    if (storedLastName) {
      setLastName(storedLastName);
    }
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const [role, setRole] = useState(""); // Add a state variable to store user role

  useEffect(() => {
    // Retrieve the user's role from local storage
    const storedRole = localStorage.getItem("role");
    if (storedRole) {
      setRole(storedRole);
    }
  }, []);

  // Function to check if the user has admin role
  const isAdmin = () => {
    return role === "admin";
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {/* Sidebar Starts */}
      <Sider
        theme="light"
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          // boxShadow: "rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px",
          boxShadow: "rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px",
          overflowY: "auto", // Make sidebar scrollable
          height: "100vh",
        }}
        className="sticky-sidebar"
      >
        <div theme="light" className="logo">
          <h2 className="text fs-3 text-center py-3 mb-0">
            <span className="sm-logo text-primary">
              <TempleBuddhistIcon />
            </span>
            <span className="lg-logo  text-primary">Mind-Collective</span>
          </h2>
        </div>
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={[selectedKey]} // Pass selectedKeys prop to highlight selected item
          onClick={handleMenuClick}
          items={[
            //Dashboard
            {
              label: "Dashboard",
              key: "",
              icon: <DashboardIcon className="fs-4" />,
            },
            //Team
            {
              label: "Team",
              key: "team",
              icon: <GroupsIcon className="fs-4" />,
              hidden: !isAdmin(),
            },

            {
              label: "Contacts",
              key: "customer",
              icon: <LaptopMacIcon className="fs-4" />,
              children: [
                {
                  label: "Customer",
                  key: "contact",
                  icon: <ContactPageIcon className="fs-4" />,
                },

                {
                  label: "Leads",
                  key: "leads",
                  icon: <PeopleAltIcon className="fs-4" />,
                },
              ],
            },

            //Custom Fields

            {
              label: "Custom Fields",
              key: "customfields",
              icon: <LabelImportantIcon className="fs-4" />,
            },
            {
              label: "Custom Fields Keys",
              key: "customfieldkeys",
              icon: <LabelImportantIcon className="fs-4" />,
            },
            //All Courses
            {
              label: "All Courses",
              key: "allcourses",
              icon: <MenuBookIcon className="fs-4" />,
            },
            // {
            //   label: "Webinar Schedule",
            //   key: "webinar",
            //   icon: <LaptopMacIcon className="fs-4" />,
            //   children: [
            //     {
            //       label: "Current Webinar",
            //       key: "currentwebinar",
            //       icon: <OndemandVideoIcon className="fs-4" />,
            //     },

            //     {
            //       label: "Future Webinar",
            //       key: "futurewebinar",
            //       icon: <ImportantDevicesIcon className="fs-4" />,
            //     },
            //     {
            //       label: "History",
            //       key: "expirewebinar",
            //       icon: <HistoryIcon className="fs-4" />,
            //     },
            //   ],
            // },
            {
              label: "Connections",
              key: "connections",
              icon: <CableIcon className="fs-4" />,
              children: [
                {
                  label: "Whatsapp Cloud",
                  key: "whatsappcloud",
                  icon: <OndemandVideoIcon className="fs-4" />,
                },

                {
                  label: "WA-Team V1",
                  key: "wateamvone",
                  icon: <ImportantDevicesIcon className="fs-4" />,
                },
                {
                  label: "WA-Team V2",
                  key: "wateamvtwo",
                  icon: <HistoryIcon className="fs-4" />,
                },
                {
                  label: "WA-RedLava",
                  key: "waredlava",
                  icon: <HistoryIcon className="fs-4" />,
                },
                {
                  label: "WA AWS",
                  key: "waaws",
                  icon: <HistoryIcon className="fs-4" />,
                },
              ],
            },
            //Campaign
            {
              label: "Campaign",
              key: "campaign",
              icon: <CampaignIcon className="fs-4" />,
              children: [
                {
                  label: "DTMF Campaign",
                  key: "dtmf_campaign",
                  icon: <AddIcCallIcon className="fs-4" />,
                },

                {
                  label: "Whatsapp Campaign",
                  key: "whatsapp_campaign",
                  icon: <WhatsAppIcon className="fs-4" />,
                },
              ],
            },

            {
              label: "BulkCampaign",
              key: "bulkcampaign",
              icon: <CampaignIcon className="fs-4" />,
            },

            //Webinar Automation
            {
              label: "Webinar Automation",
              key: "webninarsautomation",
              icon: <CampaignIcon className="fs-4" />,
              children: [
                {
                  label: "Webinar Schedule",
                  key: "webinar",
                  icon: <LaptopMacIcon className="fs-4" />,
                  children: [
                    {
                      label: "Current Webinar",
                      key: "currentwebinar",
                      icon: <OndemandVideoIcon className="fs-4" />,
                    },
                    {
                      label: "Future Webinar",
                      key: "futurewebinar",
                      icon: <ImportantDevicesIcon className="fs-4" />,
                    },

                    // {
                    //   label: "History",
                    //   key: "expirewebinar",
                    //   icon: <HistoryIcon className="fs-4" />,
                    // },
                  ],
                },

                {
                  label: "Message Scheduler",
                  key: "messagescheduler",
                  icon: <WhatsAppIcon className="fs-4" />,
                  children: [
                    {
                      label: "WA",
                      key: "webinar_automation",
                      icon: <AddIcCallIcon className="fs-4" />,
                    },

                    {
                      label: "Email",
                      key: "emails_webinar_automation",
                      icon: <EmailIcon className="fs-4" />,
                    },
                    {
                      label: "IVR calls",
                      key: "ivr_calls",
                      icon: <WhatsAppIcon className="fs-4" />,
                    },
                  ],
                },
              ],
            },

            // {
            //   label: "Reports",
            //   key: "reports",
            //   icon: <AssignmentIcon className="fs-4" />,
            // },
            //Segments
            {
              label: "Segments",
              key: "segments",
              icon: <FilterListIcon className="fs-4" />,
            },
            //Schedule
            {
              label: "Schedule",
              key: "schedule",
              icon: <AccessTimeFilledIcon className="fs-4" />,
            },
            //Tags
            {
              label: "Tags",
              key: "tags",
              icon: <TagIcon className="fs-4" />,
            },
            //AutoWapSender
            {
              label: "AutoWapSender",
              key: "autowapsender",
              icon: <SendIcon className="fs-4" />,
              children: [
                {
                  label: "AWS Dashboard",
                  key: "awsdashboard",
                  icon: <DashboardIcon className="fs-4" />,
                },

                {
                  label: "Message Template",
                  key: "messagetemplate",
                  icon: <SaveIcon className="fs-4" />,
                },

                {
                  label: "Reports",
                  key: "reportstemplate",
                  icon: <AssignmentIcon className="fs-4" />,
                },
              ],
            },

            //API Documentation
            {
              label: "API Documentation",
              key: "apidocumentation",
              icon: <IntegrationInstructionsIcon className="fs-4" />,
            },
            //Settings
            {
              label: "Settings",
              key: "settings",
              icon: <SettingsIcon className="fs-4" />,
              children: [
                {
                  label: "Profile",
                  key: "profile",
                  icon: <PersonIcon className="fs-4" />,
                },
                {
                  label: "Authorization",
                  key: "authorization",
                  icon: <VerifiedUserIcon className="fs-4" />,
                },
                {
                  label: "API Key",
                  key: "apikey",
                  icon: <KeyIcon className="fs-4" />,
                },
              ],
            },

            {
              label: "Suppression List",
              key: "suppressionlist",
              icon: <DeleteSweepIcon className="fs-4" />,
            },
          ]}
        />
      </Sider>
      {/* Sidebar Ends */}

      {/* Sub Layout Starts */}
      <Layout className="sticky-layout">
        {/* Navbar Starts */}
        <Header
          className="d-flex justify-content-between ps-1 pe-5 sticky-header"
          style={{
            padding: 0,
            // background: colorBgContainer,
            background: colorBgContainer,
            boxShadow: "rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px",
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
          <div className="d-flex gap-4 align-items-center">
            {/* <div className="position-relative">
              <IoIosNotifications className="fs-4" />
              <span className="badge bg-warning rounded-circle p-1 position-absolute">
                3
              </span>
            </div> */}
            <div className="d-flex gap-3 align-items-center dropdown">
              <div
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <h5 className="mb-0">{firstname + " " + lastname}</h5>
                  <img
                    width={32}
                    height={32}
                    style={{ borderRadius: "50%" }}
                    src={profile}
                    alt="Profile"
                  />
                </div>
              </div>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <div
                    className="dropdown-item py-1 mb-1"
                    style={{ height: "auto", lineHeight: "20px" }}
                    to="/view-profile"
                  >
                    <h5 className="mb-0">{firstname + " " + lastname}</h5>
                    <p className="mb-0">{email}</p>
                  </div>
                  <hr />
                </li>
                <li>
                  <Link
                    className="dropdown-item py-1 mb-1"
                    style={{ height: "auto", lineHeight: "20px" }}
                    to="/dashboard/profile"
                  >
                    View Profile
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item py-1 mb-1"
                    style={{
                      height: "auto",
                      lineHeight: "20px",
                      color: "red",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={handleLogout}
                  >
                    <i
                      className="ri-shut-down-line"
                      style={{ marginRight: "5px" }}
                    ></i>
                    Logout
                  </Link>
                </li>
              </div>
            </div>
          </div>
        </Header>
        {/* Navbar Ends */}

        {/* Dashboar Content Starts */}
        <Content
          className="scrollable-content"
          style={{
            margin: "0px 5px",
            padding: 2,
            minHeight: 280,
            background: colorBgContainer,
            position: "relative", // Add this to make the position of the content relative
          }}
        >
          <ToastContainer
            position="top-right"
            autoClose={250}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            theme="light"
          />
          <Outlet />
        </Content>
        {/* Dashboar Content Ends */}
        <Footer
          style={{
            textAlign: "center",
            // background: colorBgContainer,
            // background: "#68bb59",
            background: "#E6F4FF",
            boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset",
            marginTop: "0px",
            height: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p className="mb-0">
            © The{" "}
            <span
              style={{
                color: "#0d6efd",
                // color: "#ffffff",
              }}
            >
              Mind Collective
            </span>
          </p>
        </Footer>
      </Layout>
      {/* Sub Layout Ends */}
    </Layout>
  );
};
export default MainLayout;
