import React, { useEffect, useState, useMemo, useCallback } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Space, Table, Button, Modal } from "antd";
import moment from "moment";
import { Box, TextField, Grid } from "@mui/material";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const FutureWebinar = () => {
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editFutureWebinarId, setEditFutureWebinarId] = useState(null);
  const [error, setError] = useState({
    webinarIDError: "",
    futureWebinarIDError: "",
  });

  const [futurewebinar, setFutureWebinar] = useState({
    programname: "",
    webinarid: "",
    webinardate: "",
    webinartag: "",
    eventtype: "",
    duration: "",
    template: "",
    wagrouplink: "",
    webinarregistrationlink: "",
    wagroupname: "",
    transitiontime: "",
    futurewebinarid: "",
    customFields: {},
  });

  const resetForm = () => {
    setFutureWebinar({
      programname: "",
      webinarid: "",
      webinardate: "",
      webinartag: "",
      eventtype: "",
      duration: "",
      template: "",
      wagrouplink: "",
      webinarregistrationlink: "",
      wagroupname: "",
      transitiontime: "",
      futurewebinarid: "",
    });
    // setCustomField([{ key: "", value: "" }]);
    setCustomFields([]);
  };

  // Function to convert local date and time to IST string
  const toISTString = (localDateTime) => {
    return moment(localDateTime)
      .utcOffset("+05:30")
      .format("YYYY-MM-DDTHH:mm:ss");
  };

  const isValidURL = (url) => {
    // Regular expression to validate URL format
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlPattern.test(url);
  };

  // Function to handle date picker change and store IST string
  const handleDatePickerChange = (dateTimeString) => {
    setFutureWebinar({
      ...futurewebinar,
      webinardate: toISTString(dateTimeString),
    });
  };

  // Function to handle transition time picker change and store IST string
  const handleDatePickertransitiontimeChange = (dateTimeString) => {
    setFutureWebinar({
      ...futurewebinar,
      transitiontime: toISTString(dateTimeString),
    });
  };

  const fetchFutureWebinarData = async () => {
    try {
      const response = await axios.get("/api/futurewebinar/getall");
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFutureWebinarData();
  }, []);

  const handleChangeFutureWebinar = (e) => {
    const { name, value } = e.target;
    setFutureWebinar({ ...futurewebinar, [name]: value });

    if (name === "webinarid" || name === "futurewebinarid") {
      validateIDs({ ...futurewebinar, [name]: value });
    }
  };

  const validateIDs = (updatedWebinar) => {
    if (updatedWebinar.webinarid === updatedWebinar.futurewebinarid) {
      setError({
        webinarIDError: "Webinar ID and Future Webinar ID cannot be the same.",
        futureWebinarIDError:
          "Webinar ID and Future Webinar ID cannot be the same.",
      });
    } else {
      setError({
        webinarIDError: "",
        futureWebinarIDError: "",
      });
    }
  };

  const handleAddFutureWebinar = (e) => {
    e.preventDefault();

    const futurewebinarData = {
      ...futurewebinar,
      customFields: customFields,
    };

    if (editFutureWebinarId) {
      axios
        .put(
          `/api/futurewebinar/update/${editFutureWebinarId}`,
          futurewebinarData
        )
        .then((res) => {
          resetForm();
          Swal.fire({
            title: "Webinar Updated successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchFutureWebinarData();
          });
        })
        .catch((error) => {
          Swal.fire(
            "Error",
            "Error creating Webinar. Please try again.",
            "error"
          );
        });
    } else {
      axios
        .post("/api/futurewebinar/create", futurewebinarData)
        .then((res) => {
          resetForm();
          Swal.fire({
            title: "Webinar Added successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            fetchFutureWebinarData();
          });
        })
        .catch((error) => {
          Swal.fire(
            "Error",
            "Error creating Webinar. Please try again.",
            "error"
          );
        });
    }
  };

  const handleDeleteFutureWebinar = async (_id) => {
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      Swal.fire({
        title: "Deleting...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      axios
        .delete(`/api/futurewebinar/delete/${_id}`)
        .then((res) => {
          fetchFutureWebinarData();
          Swal.fire("Deleted!", "Webinar deleted successfully!", "success");
        })
        .catch((error) => {
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  const handleEditFutureWebinar = (_id) => {
    const selectedFutureWebinar = data.find((fwid) => fwid._id === _id);

    if (selectedFutureWebinar) {
      setEditFutureWebinarId(_id);
      setFutureWebinar({
        programname: selectedFutureWebinar.programname,
        webinarid: selectedFutureWebinar.webinarid,
        webinardate: selectedFutureWebinar.webinardate
          ? moment(selectedFutureWebinar.webinardate).format("YYYY-MM-DDTHH:mm")
          : "",
        webinartag: selectedFutureWebinar.webinartag,
        eventtype: selectedFutureWebinar.eventtype,
        duration: selectedFutureWebinar.duration,
        template: selectedFutureWebinar.template,
        wagrouplink: selectedFutureWebinar.wagrouplink,
        wagroupname: selectedFutureWebinar.wagroupname,
        webinarregistrationlink: selectedFutureWebinar.webinarregistrationlink,
        transitiontime: selectedFutureWebinar.transitiontime
          ? moment(selectedFutureWebinar.transitiontime).format(
              "YYYY-MM-DDTHH:mm"
            )
          : "",
        futurewebinarid: selectedFutureWebinar.futurewebinarid,
      });
      setCustomFields(selectedFutureWebinar.customFields);
    }
    setIsModalOpen(true);
  };

  const handleAddFutureWebinarButton = () => {
    setEditFutureWebinarId(null);
    resetForm();
  };

  // Extract custom field keys from the data
  const customFieldColumns = [];

  data.forEach((webinar) => {
    // Check if customFields exists and is an object
    if (webinar.customFields && typeof webinar.customFields === "object") {
      Object.keys(webinar.customFields).forEach((key) => {
        if (!customFieldColumns.some((col) => col.key === key)) {
          customFieldColumns.push({
            title: key, // Column header as the custom field key
            dataIndex: ["customFields", key], // Access the value with dot notation
            key: key,
            render: (text) => text || "-", // Render the value or placeholder if empty
            ellipsis: true,
          });
        }
      });
    }
  });

  //Save the Duplicate details
  const [duplicatedWebinar, setDuplicatedWebinar] = useState({
    programname: "",
    webinarid: "",
    webinardate: "",
    webinartag: "",
    eventtype: "",
    duration: "",
    template: "",
    wagrouplink: "",
    webinarregistrationlink: "",
    wagroupname: "",
    transitiontime: "",
    futurewebinarid: "",
    customFields: {},
  });
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleDuplicateWebinar = (webinar) => {
    const duplicated = {
      ...webinar,
      webinardate: formatDate(webinar.webinardate), // Format the date
      transitiontime: formatDate(webinar.transitiontime),
      customFields: { ...webinar.customFields },
    };

    setDuplicatedWebinar(duplicated);
    setIsModalVisible(true); // Open the modal for editing
  };
  // Function to format date to 'yyyy-MM-ddThh:mm'
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const handleSaveDuplicatedWebinar = async () => {
    try {
      // Create a copy of the duplicatedWebinar object excluding the unwanted fields
      const { _id, day, date, time, ...cleanedWebinar } = duplicatedWebinar;

      // Example URL of your backend API
      const url = "/api/futurewebinar/create"; // Change this to your actual API endpoint

      // Send cleanedWebinar (without day, date, and time) to the backend API
      const response = await axios.post(url, cleanedWebinar);

      if (response.status === 201 || response.status === 200) {
        console.log("Duplicated Webinar saved successfully:", response.data);

        // Optionally, update the table data with the new entry
        // setData((prevData) => [...prevData, response.data]);

        // Fetch updated webinar data
        fetchFutureWebinarData();

        // Show success SweetAlert
        Swal.fire({
          title: "Success!",
          text: "The duplicated webinar has been successfully added.",
          icon: "success",
          confirmButtonText: "OK",
        });

        // Close the modal after saving
        setIsModalVisible(false);
      } else {
        console.error(
          "Failed to save duplicated webinar:",
          response.statusText
        );

        // Show error SweetAlert
        Swal.fire({
          title: "Error!",
          text: "Failed to save the duplicated webinar. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error saving duplicated webinar:", error);
      // Show error SweetAlert in case of an exception
      Swal.fire({
        title: "Error!",
        text: "An error occurred while saving the duplicated webinar.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const columns = [
    {
      title: "ID",
      key: "id",
      render: (text, record, index) => index + 1,
      fixed: "left",
      width: 50,
    },
    {
      title: "PROG. NAME",
      dataIndex: "programname",
      key: "programname",
      ellipsis: true,
    },
    {
      title: "Webinar ID",
      dataIndex: "webinarid",
      key: "webinarid",
      ellipsis: true,
    },
    {
      title: "Webinar Date & Time",
      dataIndex: "webinardate",
      key: "webinardate",
      ellipsis: true,
      render: (createdAt) => new Date(createdAt).toLocaleString(),
    },
    {
      title: "Webinar Tag",
      dataIndex: "webinartag",
      key: "webinartag",
      ellipsis: true,
    },

    {
      title: "Event Type",
      dataIndex: "eventtype",
      key: "eventtype",
      ellipsis: true,
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      ellipsis: true,
    },

    {
      title: "Template No.",
      dataIndex: "template",
      key: "template",
      ellipsis: true,
    },
    {
      title: "WA Group Joining Link",
      dataIndex: "wagrouplink",
      key: "wagrouplink",
      ellipsis: true,
    },
    {
      title: "Webinar Registration Link",
      dataIndex: "webinarregistrationlink",
      key: "webinarregistrationlink",
      ellipsis: true,
    },
    {
      title: "WA Group Name",
      dataIndex: "wagroupname",
      key: "wagroupname",
      ellipsis: true,
    },

    // Add dynamically generated custom field columns here
    ...customFieldColumns,

    {
      title: "Transition time",
      dataIndex: "transitiontime",
      key: "transitiontime",
      ellipsis: true,
      render: (createdAt) => new Date(createdAt).toLocaleString(),
    },
    {
      title: "Future Webinar ID",
      dataIndex: "futurewebinarid",
      key: "futurewebinarid",
      ellipsis: true,
    },
    {
      title: "ACTION",
      key: "action",
      width: 150,
      render: (text, record) => (
        <Space size="middle">
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            className="edit-btn"
            onClick={() => handleEditFutureWebinar(record._id)}
          >
            <i className="ri-pencil-fill"></i>
          </button>
          <button
            onClick={() => handleDeleteFutureWebinar(record._id)}
            className="delete-btn"
          >
            <i className="ri-delete-bin-fill"></i>
          </button>
          <button
            onClick={() => handleDuplicateWebinar(record)}
            className="edit-btn"
          >
            <i class="ri-file-copy-line"></i>
          </button>
        </Space>
      ),
    },
  ];

  // New CustomField Functionality Starts

  const [customsData, setCustomsData] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [nextCustomFieldType, setNextCustomFieldType] = useState("");
  // New CustomField Functionality Ends

  const fetchCustomsData = async () => {
    try {
      const response = await axios.get("/api/customfieldkey/getall");
      setCustomsData(response.data);
    } catch (error) {
      console.error("Error fetching customs data:", error);
    }
  };

  // Fetch Contact data on component mount
  useEffect(() => {
    fetchCustomsData();
  }, []);

  const handleCustomFieldValueChange = (key, value) => {
    setCustomFields((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleAddCustomField = () => {
    if (nextCustomFieldType) {
      setCustomFields((prev) => ({
        ...prev,
        [nextCustomFieldType]: "", // Initialize with an empty value
      }));
      setNextCustomFieldType(""); // Reset selection
    }
  };

  const handleRemoveCustomField = useCallback((key) => {
    setCustomFields((prev) => {
      const newFields = { ...prev };
      delete newFields[key];
      return newFields;
    });
  }, []);

  // Duplicate Custom Fields

  const [nextDuplicateCustomFieldType, setNextDuplicateCustomFieldType] =
    useState("");

  const handleAddDuplicateCustomField = () => {
    if (nextDuplicateCustomFieldType) {
      setDuplicatedWebinar((prev) => ({
        ...prev,
        customFields: {
          ...prev.customFields,
          [nextDuplicateCustomFieldType]: "", // Initialize with empty string
        },
      }));
      setNextDuplicateCustomFieldType(""); // Reset field type selection
    }
  };

  const handleDuplicateRemoveCustomField = (key) => {
    setDuplicatedWebinar((prev) => {
      const newCustomFields = { ...prev.customFields };
      delete newCustomFields[key]; // Remove the custom field
      return { ...prev, customFields: newCustomFields };
    });
  };

  const handleDuplicateCustomFieldChange = (key, value) => {
    setDuplicatedWebinar((prev) => ({
      ...prev,
      customFields: {
        ...prev.customFields,
        [key]: value,
      },
    }));
  };

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">Future Webinar</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div id="contactList" className="card">
              <div className="card-header py-3">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <div className="d-flex flex-wrap flex-grow-1 gap-2">
                    <button
                      type="button"
                      className="btn btn-primary add-btn"
                      onClick={handleAddFutureWebinarButton}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <i className="ri-add-fill me-1 align-bottom"></i> Future
                      Webinar
                    </button>
                    {/* Future Webinar Modal */}
                    <div
                      className="modal fade"
                      id="exampleModal"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden={!isModalOpen}
                      style={{ display: isModalOpen ? "block" : "none" }}
                    >
                      <div
                        className="modal-dialog modal-lg modal-dialog-centered"
                        style={{ maxWidth: "62%" }}
                      >
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              {editFutureWebinarId
                                ? "Update Future Webinar"
                                : "Add Future Webinar"}
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <Box>
                              {/* Group Of 3 */}
                              <Grid
                                container
                                spacing={1}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {/* Program Name */}
                                <Grid item xs={4}>
                                  <h6>Program Name</h6>
                                  <TextField
                                    size="small"
                                    color="primary"
                                    fullWidth
                                    name="programname"
                                    value={futurewebinar.programname}
                                    onChange={handleChangeFutureWebinar}
                                  />
                                </Grid>
                                {/* Webinar ID */}
                                <Grid item xs={4}>
                                  <h6>Webinar ID</h6>
                                  <TextField
                                    size="small"
                                    color="primary"
                                    fullWidth
                                    name="webinarid"
                                    value={futurewebinar.webinarid}
                                    onChange={handleChangeFutureWebinar}
                                    error={!!error.webinarIDError}
                                    helperText={error.webinarIDError}
                                  />
                                </Grid>
                                {/* Webinar Date and Time */}
                                <Grid item xs={4}>
                                  <h6>Webinar Date</h6>
                                  <input
                                    type="datetime-local"
                                    id="webinardate"
                                    name="webinardate"
                                    value={futurewebinar.webinardate}
                                    onChange={(e) =>
                                      handleDatePickerChange(e.target.value)
                                    }
                                    className="form-select w-100"
                                  />
                                </Grid>
                              </Grid>
                              {/* Group Of 3 */}
                              <Grid
                                container
                                spacing={1}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {/* Webinar Tag */}
                                <Grid item xs={4}>
                                  <h6 className="mt-3">Webinar Tag</h6>
                                  <TextField
                                    size="small"
                                    color="primary"
                                    fullWidth
                                    name="webinartag"
                                    value={futurewebinar.webinartag}
                                    onChange={handleChangeFutureWebinar}
                                  />
                                </Grid>
                                {/* Event Type */}
                                <Grid item xs={4}>
                                  <h6 className="mt-3">Event Type</h6>
                                  {/* Event Type Selector */}
                                  <select
                                    className="form-select mt-2"
                                    aria-label="Default select example"
                                    name="eventtype"
                                    value={futurewebinar.eventtype}
                                    onChange={handleChangeFutureWebinar}
                                  >
                                    <option selected value="" disabled>
                                      --Select Event Type--
                                    </option>
                                    <option
                                      value="zoom meeting"
                                      name="whatsapp"
                                    >
                                      Zoom meeting
                                    </option>
                                    <option
                                      value="zoom webinar"
                                      name="facebook"
                                    >
                                      Zoom Webinar
                                    </option>
                                    <option
                                      value="ever webinar"
                                      name="instagram"
                                    >
                                      Ever Webinar
                                    </option>
                                    <option
                                      value="webinar jam"
                                      name="instagram"
                                    >
                                      Webinar Jam
                                    </option>
                                  </select>
                                </Grid>
                                {/* Duration */}
                                <Grid item xs={4}>
                                  <h6 className="mt-3">Duration</h6>
                                  <TextField
                                    size="small"
                                    color="primary"
                                    fullWidth
                                    name="duration"
                                    value={futurewebinar.duration}
                                    onChange={handleChangeFutureWebinar}
                                  />
                                </Grid>
                              </Grid>
                              {/* Group Of 3 */}
                              <Grid
                                container
                                spacing={1}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {/* Template No. */}
                                <Grid item xs={4}>
                                  <h6 className="mt-3">Template No.</h6>
                                  <TextField
                                    size="small"
                                    color="primary"
                                    fullWidth
                                    name="template"
                                    value={futurewebinar.template}
                                    onChange={handleChangeFutureWebinar}
                                  />
                                </Grid>
                                {/* Wagroup Link */}
                                <Grid item xs={4}>
                                  <h6 className="mt-3">Wagroup link</h6>
                                  <TextField
                                    size="small"
                                    color="primary"
                                    fullWidth
                                    name="wagrouplink"
                                    value={futurewebinar.wagrouplink}
                                    onChange={handleChangeFutureWebinar}
                                    error={
                                      futurewebinar.wagrouplink &&
                                      !isValidURL(futurewebinar.wagrouplink)
                                    }
                                    helperText={
                                      futurewebinar.wagrouplink &&
                                      !isValidURL(futurewebinar.wagrouplink) &&
                                      "Please enter a valid URL"
                                    }
                                  />
                                </Grid>
                                {/* Webinar Registration Link */}
                                <Grid item xs={4}>
                                  <h6 className="mt-3">
                                    Webinar Registration Link
                                  </h6>
                                  <TextField
                                    size="small"
                                    color="primary"
                                    fullWidth
                                    name="webinarregistrationlink"
                                    value={
                                      futurewebinar.webinarregistrationlink
                                    }
                                    onChange={handleChangeFutureWebinar}
                                    error={
                                      futurewebinar.webinarregistrationlink &&
                                      !isValidURL(
                                        futurewebinar.webinarregistrationlink
                                      )
                                    }
                                    helperText={
                                      futurewebinar.webinarregistrationlink &&
                                      !isValidURL(
                                        futurewebinar.webinarregistrationlink
                                      ) &&
                                      "Please enter a valid URL"
                                    }
                                  />
                                </Grid>
                              </Grid>
                              {/* Group Of 3 */}
                              <Grid
                                container
                                spacing={1}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {/* WA Group Name */}
                                <Grid item xs={4}>
                                  <h6 className="mt-3">WA Group Name</h6>
                                  <TextField
                                    size="small"
                                    color="primary"
                                    fullWidth
                                    name="wagroupname"
                                    value={futurewebinar.wagroupname}
                                    onChange={handleChangeFutureWebinar}
                                  />
                                </Grid>
                                {/* Transition Time */}
                                <Grid item xs={4}>
                                  <h6 className="mt-3">Transition Time</h6>
                                  <input
                                    type="datetime-local"
                                    id="transitiontime"
                                    name="transitiontime"
                                    value={futurewebinar.transitiontime}
                                    onChange={(e) =>
                                      handleDatePickertransitiontimeChange(
                                        e.target.value
                                      )
                                    }
                                    className="form-select w-100"
                                  />
                                </Grid>
                                {/* Future Webinar ID */}
                                <Grid item xs={4}>
                                  <h6 className="mt-3">Future Webinar ID</h6>
                                  <TextField
                                    size="small"
                                    color="primary"
                                    fullWidth
                                    name="futurewebinarid"
                                    value={futurewebinar.futurewebinarid}
                                    onChange={handleChangeFutureWebinar}
                                    error={!!error.futureWebinarIDError}
                                    helperText={error.futureWebinarIDError}
                                  />
                                </Grid>
                              </Grid>
                              <div>
                                {Object.entries(customFields).map(
                                  ([key, value]) => (
                                    <div key={key}>
                                      <Grid
                                        container
                                        spacing={1}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Grid item xs={11}>
                                          <TextField
                                            size="small"
                                            fullWidth
                                            label={key}
                                            type="text"
                                            margin="normal"
                                            value={value}
                                            onChange={(e) =>
                                              handleCustomFieldValueChange(
                                                key,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={1}>
                                          <MinusCircleOutlined
                                            style={{
                                              fontSize: "25px",
                                              textAlign: "center",
                                            }}
                                            onClick={() =>
                                              handleRemoveCustomField(key)
                                            }
                                          />
                                        </Grid>
                                      </Grid>
                                    </div>
                                  )
                                )}

                                <Grid
                                  container
                                  spacing={1}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "10px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <Grid item xs={9}>
                                    <select
                                      className="form-select"
                                      margin="normal"
                                      value={nextCustomFieldType}
                                      onChange={(e) =>
                                        setNextCustomFieldType(e.target.value)
                                      }
                                    >
                                      <option value="" selected disabled>
                                        --Select a Custom Field--
                                      </option>
                                      {customsData.map((custom) => (
                                        <option
                                          key={custom._id}
                                          value={custom.key}
                                        >
                                          {custom.key}
                                        </option>
                                      ))}
                                    </select>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Button
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                      type="dashed"
                                      onClick={handleAddCustomField}
                                      icon={<PlusOutlined />}
                                    >
                                      Add field
                                    </Button>
                                  </Grid>
                                </Grid>
                              </div>

                              {/* New CustomField Functionality */}
                            </Box>
                          </div>
                          <div className="modal-footer">
                            <button
                              onClick={handleAddFutureWebinar}
                              type="button"
                              className="btn btn-primary"
                              data-bs-dismiss="modal"
                            >
                              {editFutureWebinarId ? "Update" : "Save"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      {/* Modal for editing duplicated webinar */}
                      <Modal
                        title="Edit Duplicated Webinar"
                        visible={isModalVisible}
                        onOk={handleSaveDuplicatedWebinar}
                        onCancel={() => setIsModalVisible(false)}
                        width={800}
                      >
                        {/* Group Of 3 */}
                        <Grid
                          container
                          spacing={1}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {/* Program Name */}
                          <Grid item xs={4}>
                            {/* Form Fields */}
                            <TextField
                              size="small"
                              color="primary"
                              fullWidth
                              label="Program Name"
                              name="programname"
                              value={duplicatedWebinar?.programname || ""}
                              onChange={(e) =>
                                setDuplicatedWebinar({
                                  ...duplicatedWebinar,
                                  programname: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              size="small"
                              color="primary"
                              fullWidth
                              label="Webinar ID"
                              name="webinarid"
                              value={duplicatedWebinar?.webinarid || ""}
                              onChange={(e) =>
                                setDuplicatedWebinar({
                                  ...duplicatedWebinar,
                                  webinarid: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              size="small"
                              color="primary"
                              fullWidth
                              label="Webinar Date"
                              name="webinardate"
                              type="datetime-local"
                              value={duplicatedWebinar?.webinardate || ""}
                              onChange={(e) =>
                                setDuplicatedWebinar({
                                  ...duplicatedWebinar,
                                  webinardate: e.target.value,
                                })
                              }
                            />
                          </Grid>
                        </Grid>
                        {/* Group Of 3 */}
                        <Grid
                          container
                          spacing={1}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {/* Program Name */}
                          <Grid item xs={4} className="mt-3">
                            <TextField
                              size="small"
                              color="primary"
                              fullWidth
                              label="Webinar Tag"
                              name="webinartag"
                              value={duplicatedWebinar?.webinartag || ""}
                              onChange={(e) =>
                                setDuplicatedWebinar({
                                  ...duplicatedWebinar,
                                  webinartag: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={4} className="mt-3">
                            <select
                              className="form-select"
                              name="eventtype"
                              value={duplicatedWebinar?.eventtype || ""}
                              onChange={(e) =>
                                setDuplicatedWebinar({
                                  ...duplicatedWebinar,
                                  eventtype: e.target.value,
                                })
                              }
                            >
                              <option value="" disabled>
                                --Select Event Type--
                              </option>
                              <option value="zoom meeting">Zoom meeting</option>
                              <option value="zoom webinar">Zoom Webinar</option>
                              <option value="ever webinar">Ever Webinar</option>
                              <option value="webinar jam">Webinar Jam</option>
                            </select>
                          </Grid>
                          <Grid item xs={4} className="mt-3">
                            <TextField
                              size="small"
                              color="primary"
                              fullWidth
                              label="Duration"
                              name="duration"
                              value={duplicatedWebinar?.duration || ""}
                              onChange={(e) =>
                                setDuplicatedWebinar({
                                  ...duplicatedWebinar,
                                  duration: e.target.value,
                                })
                              }
                            />
                          </Grid>
                        </Grid>
                        {/* Group Of 3 */}
                        <Grid
                          container
                          spacing={1}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {/* Program Name */}
                          <Grid item xs={4} className="mt-3">
                            {" "}
                            <TextField
                              size="small"
                              color="primary"
                              fullWidth
                              label="Template No."
                              name="template"
                              value={duplicatedWebinar?.template || ""}
                              onChange={(e) =>
                                setDuplicatedWebinar({
                                  ...duplicatedWebinar,
                                  template: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={4} className="mt-3">
                            {" "}
                            <TextField
                              size="small"
                              color="primary"
                              fullWidth
                              label="Wagroup Link"
                              name="wagrouplink"
                              value={duplicatedWebinar?.wagrouplink || ""}
                              onChange={(e) =>
                                setDuplicatedWebinar({
                                  ...duplicatedWebinar,
                                  wagrouplink: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={4} className="mt-3">
                            <TextField
                              size="small"
                              color="primary"
                              fullWidth
                              label="Webinar Registration Link"
                              name="webinarregistrationlink"
                              value={
                                duplicatedWebinar?.webinarregistrationlink || ""
                              }
                              onChange={(e) =>
                                setDuplicatedWebinar({
                                  ...duplicatedWebinar,
                                  webinarregistrationlink: e.target.value,
                                })
                              }
                            />
                          </Grid>
                        </Grid>

                        {/* Group Of 3 */}
                        <Grid
                          container
                          spacing={1}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {/* Program Name */}
                          <Grid item xs={4} className="mt-3">
                            <TextField
                              size="small"
                              color="primary"
                              fullWidth
                              label="WA Group Name"
                              name="wagroupname"
                              value={duplicatedWebinar?.wagroupname || ""}
                              onChange={(e) =>
                                setDuplicatedWebinar({
                                  ...duplicatedWebinar,
                                  wagroupname: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={4} className="mt-3">
                            <TextField
                              size="small"
                              color="primary"
                              fullWidth
                              label="Transition Time"
                              name="transitiontime"
                              type="datetime-local"
                              value={duplicatedWebinar?.transitiontime || ""}
                              onChange={(e) =>
                                setDuplicatedWebinar({
                                  ...duplicatedWebinar,
                                  transitiontime: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item xs={4} className="mt-3">
                            <TextField
                              size="small"
                              color="primary"
                              fullWidth
                              label="Future Webinar ID"
                              name="futurewebinarid"
                              value={duplicatedWebinar?.futurewebinarid || ""}
                              onChange={(e) =>
                                setDuplicatedWebinar({
                                  ...duplicatedWebinar,
                                  futurewebinarid: e.target.value,
                                })
                              }
                            />
                          </Grid>
                        </Grid>
                        <div>
                          {/* Add any custom fields if needed */}
                          {duplicatedWebinar &&
                            duplicatedWebinar.customFields &&
                            Object.entries(duplicatedWebinar.customFields).map(
                              ([key, value]) => (
                                <Grid
                                  container
                                  spacing={1}
                                  key={key}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Grid item xs={11}>
                                    <TextField
                                      className="mt-3"
                                      size="small"
                                      fullWidth
                                      label={key}
                                      value={value}
                                      onChange={(e) =>
                                        handleDuplicateCustomFieldChange(
                                          key,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={1}>
                                    <MinusCircleOutlined
                                      style={{
                                        fontSize: "25px",
                                        textAlign: "center",
                                      }}
                                      onClick={() =>
                                        handleDuplicateRemoveCustomField(key)
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              )
                            )}
                          <Grid
                            container
                            spacing={1}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "10px",
                              marginBottom: "20px",
                            }}
                          >
                            <Grid item xs={9}>
                              <select
                                className="form-select"
                                margin="normal"
                                value={nextDuplicateCustomFieldType}
                                onChange={(e) =>
                                  setNextDuplicateCustomFieldType(
                                    e.target.value
                                  )
                                }
                              >
                                <option value="" selected disabled>
                                  --Select a Custom Field--
                                </option>
                                {customsData.map((custom) => (
                                  <option key={custom._id} value={custom.key}>
                                    {custom.key}
                                  </option>
                                ))}
                              </select>
                            </Grid>
                            <Grid item xs={2}>
                              <Button
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                type="dashed"
                                onClick={handleAddDuplicateCustomField}
                                icon={<PlusOutlined />}
                              >
                                Add field
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      </Modal>
                    </div>
                  </div>
                  <div className="flex-shrink-0"></div>
                </div>
                <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
                  <div className="flex-shrink-0">
                    <div className="hstack text-nowrap gap-2 align-item-center"></div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Table
                  dataSource={data}
                  columns={columns}
                  rowKey="_id"
                  scroll={{ x: true }}
                  pagination={{
                    pageSize: 5,
                  }}
                  components={{
                    header: {
                      cell: ({ style, ...restProps }) => (
                        <th
                          {...restProps}
                          style={{
                            ...style,
                            backgroundColor: "#c6c6c6",
                            color: "black",
                            textAlign: "center",
                          }}
                        />
                      ),
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FutureWebinar;
